import React from "react";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import classes from "./Register.module.css";
import register from "../../assets/images/registration_image.png";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { FcGoogle } from "react-icons/fc";
import axios from "axios";
import { useEffect, useState } from "react";
import LoadingButton from "../../components/LoadingButton/LoadingButton";
import { registerSchema } from "../../schemas";
import styles from "../common_css/CommonClasses.module.css";
import { IoArrowBackSharp } from "react-icons/io5";

const Register = (props) => {
	const [loading, setLoading] = useState(false);
	const [verificationRequested, setVerificationRequested] = useState(null);
	const verificationToggler = () => {
		setVerificationRequested(null);
	};
	const initialValues = {
		name: "",
		password: "",
		email: "",
		checkbox: false,
	};
	const onSubmit = async (values, actions) => {
		setLoading(true);
		const data = await props.register(values);
		console.log(data);
		if (data === "error" || data === "Error") {
			setLoading(false);
			return;
		}
		actions.setValues(initialValues);
		setVerificationRequested(values.email);
		setLoading(false);
	};

	const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
		useFormik({
			initialValues: initialValues,
			validationSchema: registerSchema,
			onSubmit,
		});
	const googleSuccess = async (res) => {
		const userInfo = await axios
			.get("https://www.googleapis.com/oauth2/v3/userinfo", {
				headers: { Authorization: `Bearer ${res.access_token}` },
			})
			.then((res) => {
				// console.log(res);
				// console.log(res.data);
				return res.data;
			});
		// console.log("user : ", userInfo);

		if (userInfo) {
			const data = await props.googleLogin(userInfo);
			// console.log(data.user_name);
			props.setName(data.user_name);
			const names = data.user_name.split(" ");
			props.setLastname(names[names.length - 1]);
		}
	};
	const login = useGoogleLogin({
		onSuccess: googleSuccess,
		onError: googleSuccess,
		clientId:
			"45272784584-8cm2n74qhvoo3mjh4s8d40tm3377c9sq.apps.googleusercontent.com",
	});
	const passwordToggleHandler = (e) => {
		const password = document.querySelector("#password");
		if (e.target.className.includes("fa-eye-slash")) {
			e.target.className = e.target.className.replace("fa-eye-slash", "fa-eye");
			password.setAttribute("type", "text");
		} else {
			e.target.className = e.target.className.replace("fa-eye", "fa-eye-slash");
			password.setAttribute("type", "password");
		}
	};
	return (
		<div className={classes["container"] + " " + classes["d-flex"]}>
			<div className={classes["register-form-section"]}>
				<div className={classes["register-form-heading-section"]}>
					<p className={classes["register-form-heading"]}>Volkano AI</p>
				</div>
				{!verificationRequested ? (
					<div className={classes["register-form"]}>
						<div className={classes["register-form-heading-section"]}>
							<p className={classes["register-heading"]}>Sign up to Continue</p>
							<p className={classes["register-subheading"]}>
								Sign up with google or enter your details
							</p>
						</div>
						<div className={classes["register-form-input-section"]}>
							<form
								onSubmit={handleSubmit}
								className={classes["register-inputs"]}
							>
								<label className={classes["inputLabel"]} htmlFor="name">
									Full Name
								</label>
								<input
									className={
										styles["input-container"] + " " + styles["email-input"]
									}
									id="name"
									name="name"
									type="text"
									onChange={handleChange}
									value={values.name}
									placeholder="Enter full name"
								/>

								<label className={classes["inputLabel"]} htmlFor="email">
									Email address
								</label>
								<input
									className={
										styles["input-container"] +
										" " +
										(errors.email && touched.email
											? styles["input-error"]
											: styles["email-input"])
									}
									id="email"
									name="email"
									type="email"
									onChange={handleChange}
									value={values.email}
									onBlur={handleBlur}
									placeholder="Enter email"
								/>
								{errors.email && touched.email && (
									<p className={styles["error-text"]}>{errors.email}</p>
								)}

								<label className={classes["inputLabel"]} htmlFor="password">
									Password
								</label>
								<div
									className={
										styles["input-container"] +
										" " +
										(errors.password && touched.password
											? styles["input-error"]
											: styles["email-input"])
									}
									style={{ display: "flex", alignItems: "center" }}
								>
									<input
										className={styles["input"]}
										id="password"
										type="password"
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.password}
										placeholder="********"
									/>
									<i
										className={"far fa-eye-slash " + styles["password-toggle"]}
										id="togglePassword"
										onClick={passwordToggleHandler}
									></i>
								</div>
								{errors.password && touched.password && (
									<p className={styles["error-text"]}>{errors.password}</p>
								)}
								<div
									className={classes["d-flex"]}
									style={{ alignItems: "center", gap: "4px" }}
								>
									<input
										className={classes["inputCheckbox"]}
										type="checkbox"
										id="checkbox"
										name="checkbox"
										onChange={handleChange}
										value={values.checkbox}
									/>
									<label
										className={
											classes["inputLabel"] +
											" " +
											classes["inputCheckboxLabel"]
										}
										htmlFor="checkbox"
									>
										Remember Me
									</label>
								</div>
								<div className={classes["register-button-section"]}>
									{/* <button className={classes["register-button"]} type="submit">
									Sign Up
								</button> */}
									<LoadingButton
										text="Sign Up"
										loading={loading}
										disabled={loading}
										type="submit"
									/>
								</div>
							</form>
							<button
								onClick={() => login()}
								className={classes["google-login-button"]}
							>
								<FcGoogle className={classes["google-icon"]} />
								Continue with Google
							</button>
						</div>
						<div className={classes["register-form-consent-section"]}>
							<p className={classes["register-consent-text"]}>
								By confirming your email, you agree to our{" "}
								<Link to="/terms" className={classes["register-consent-link"]}>
									Terms of Service
								</Link>{" "}
								and that you have read and understood our{" "}
								<Link
									to="/privacy"
									className={classes["register-consent-link"]}
								>
									Privacy Policy
								</Link>
								.
							</p>
							<p className={classes["login-text"]}>
								Already have an account.{" "}
								<Link to="/" className={classes["login-link"]}>
									Log In
								</Link>
							</p>
						</div>
					</div>
				) : (
					<div
						className={styles["d-flex-col"] + " "}
						style={{ marginTop: "3rem" }}
					>
						<IoArrowBackSharp
							className={styles["icon-back"] + " " + styles["icon-plain"]}
							onClick={verificationToggler}
						/>
						<p
							className={styles["section-heading-text"] + " " + styles["text"]}
						>
							We have sent a verification link at {verificationRequested}.
							Please check your mail for further registration process.
						</p>
					</div>
				)}
			</div>
			<div className={classes["register-image-section"]}>
				<img className={classes["register-image"]} src={register}></img>
			</div>
		</div>
	);
};

export default Register;
