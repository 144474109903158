import React from "react";
import { useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
import { DndContext } from "@dnd-kit/core";
import classes from "./AdAnalysis.module.css";
import { FaChevronDown } from "react-icons/fa6";
import styles from "../common_css/CommonClasses.module.css";
import Draggable from "../../components/Draggable/Draggable";
import Droppable from "../../components/Droppable/Droppable";

const AdAnalysis = (props) => {
	const [active, setActive] = useState("Select Products");
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [products, setProducts] = useState(["nike", "adidas", "puma"]);
	const dropdownHandler = (e) => {
		setIsDropdownOpen((prevState) => !prevState);
	};
	const productSelectHandler = (e) => {
		setActive(e.target.textContent);
		setIsDropdownOpen(false);
	};
	const [propertyItems, setPropertyItems] = useState({
		"pain-point": [],
		benefits: [],
		feature: [],
		"selling-product": [],
	});
	const addItemsToCart = (e) => {
		const newItem = e.active.data.current?.title;
		const dataClass = e.active.data.current?.classes["data-class"];
		const updatedPropertyItems = { ...propertyItems };
		if (!propertyItems[dataClass]) propertyItems[dataClass] = [];
		if (propertyItems[dataClass].includes(newItem))
			updatedPropertyItems[dataClass] = propertyItems[dataClass].filter(
				(item) => item !== newItem
			);
		else updatedPropertyItems[dataClass].push(newItem);
		setPropertyItems(updatedPropertyItems);
	};

	const clickHandler = (e) => {
		console.log(e.target.closest(".property-symbol"));
	};
	return (
		<DndContext onDragEnd={addItemsToCart}>
			<div className={classes["container"]}>
				<div className={classes["ad-analysis-container"]}>
					<div className={classes["welcome-section"]}>
						<div className={classes["select-products-section"]}>
							<p className={classes["welcome-heading-text"]}>Ad Analysis</p>
							<ul className={classes["product-items-list"]}>
								<li
									key="0"
									className={
										classes["product-item"] +
										" " +
										classes["product-item-active"]
									}
									onClick={dropdownHandler}
								>
									<p style={{ margin: 0, padding: 0 }}>{active}</p>
									<FaChevronDown />
								</li>
								{isDropdownOpen ? (
									products.map((product, index) => (
										<li
											className={classes["product-item"]}
											key={index + 1}
											onClick={productSelectHandler}
										>
											{product}
										</li>
									))
								) : (
									<></>
								)}
							</ul>
						</div>

						<div className={classes["phrases-heading-section"]}>
							<p className={classes["selected-heading-text"]}>
								Selected Phrases
							</p>
						</div>
					</div>
				</div>
				<div className={classes["ad-analysis-main-section"]}>
					<div className={classes["ad-properties-section"]}>
						<div className={classes["properties-card"]}>
							<p className={classes["properties-heading"]}>Pain Points</p>
							<ul className={classes["properties-list"]}>
								{props.data.map((val, idx) => (
									<li
										key={val["pain-point"]}
										className={classes["property-item"]}
										onClick={clickHandler}
									>
										<Draggable key={val["pain-point"]} data-class="pain-point">
											{val["pain-point"]}
										</Draggable>{" "}
										<FaPlus className={styles["icon-coloured"]} />
									</li>
								))}
							</ul>
						</div>
						<div className={classes["properties-card"]}>
							<p className={classes["properties-heading"]}>Features</p>
							<ul className={classes["properties-list"]}>
								{props.data.map((val, idx) => (
									<li
										key={val["feature"]}
										className={
											classes["property-item"] + " " + classes["feature"]
										}
									>
										<Draggable key={val["feature"]} data-class="feature">
											{val["feature"]}
										</Draggable>{" "}
										<FaPlus className={styles["icon-coloured"]} />
									</li>
								))}
							</ul>
						</div>
						<div className={classes["properties-card"]}>
							<p className={classes["properties-heading"]}>Selling Products</p>
							<ul className={classes["properties-list"]}>
								{props.data.map((val, idx) => (
									<li
										key={val["selling-product"]}
										className={classes["property-item"]}
									>
										<Draggable
											key={val["selling-product"]}
											data-class="selling-product"
										>
											{val["selling-product"]}
										</Draggable>{" "}
										<FaPlus className={styles["icon-coloured"]} />
									</li>
								))}
							</ul>
						</div>
						<div className={classes["properties-card"]}>
							<p className={classes["properties-heading"]}>Benefits</p>
							<ul className={classes["properties-list"]}>
								{props.data.map((val, idx) => (
									<li
										key={val["benefits"]}
										className={classes["property-item"]}
									>
										<Draggable key={val["benefits"]} data-class="benefits">
											{val["benefits"]}
										</Draggable>
										<FaPlus className={styles["icon-coloured"]} />
									</li>
								))}
							</ul>
						</div>
					</div>
					<div className={classes["properties-container"]}>
						<Droppable items={propertyItems} />
					</div>
				</div>
			</div>
		</DndContext>
	);
};

export default AdAnalysis;
