import logo from "./logo.svg";
import { useState, useEffect } from "react";
import SideNav from "./pages/global/sidebar/SideNav";
import Dashboard from "./pages/dashboard/Dashboard";
import AdAnalysis from "./pages/adAnalysis/AdAnalysis";
import GeneratedAds from "./pages/generatedAds/GeneratedAds";
import AdEdit from "./pages/adEdit/AdEdit";
import AdReview from "./pages/adReview/AdReview";
import Publish from "./pages/publish/Publish";
import AdStructure from "./pages/adStructure/AdStructure";
import Integrations from "./pages/integrations/Integrations";
import Settings from "./pages/global/settings/Settings";
import Login from "./pages/login/Login";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { SidenavProvider } from "./pages/global/sidebar/sidenavContext";
import Privacy from "./pages/privacy/Privacy";
import Terms from "./pages/terms/Terms";
import Register from "./pages/register/Register";
import { useAuth } from "./store/AuthContext";
import TypeForm from "./components/TypeForm/TypeForm";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword";
import Topbar from "./components/Topbar/Topbar";
import classes from "./App.module.css";
import { set } from "date-fns";
import Billing from "./pages/billing/Billing";
import Support from "./pages/support/Support";
import Profile from "./pages/profile/Profile";
import Success from "./pages/success/Success";
import Verify from "./pages/verify/Verify";

function App() {
	const [lastname, setLastname] = useState("User");
	const {
		user,
		userLogin,
		userLogout,
		userRegister,
		userUpdate,
		userComment,
		forgotPassword,
		otpVerify,
		passwordReset,
		imageUpdate,
		googleLogin,
		accountDetails,
		username,
		setUsername,
	} = useAuth();

	const questions = [
		{
			text: "What is your name?",
			options: ["John", "Jane", "Other"],
		},
		{
			text: "Where are you from?",
			options: ["New York", "Los Angeles", "Chicago"],
		},
		{
			text: "What is your favorite color?",
			options: ["Red", "Blue", "Green", "Other"],
		},
		{
			text: "Please provide any additional comments:",
		},
	];

	const dataValues = [
		{
			"pain-point": "Pigmentation",
			feature: "Face & Body Exfoliator",
			benefits: "Remarkable results on your skin",
			"selling-product": "Pigmentation removal",
		},
		{
			"pain-point": "acne breakouts",
			feature: "Charcoal Bath Bar",
			benefits: "Embrace your true radiance",
			"selling-product": "Exfoliation",
		},
		{
			"pain-point": "Hair removal being full of OUCH",
			feature: "Hair Removal Powder",
			benefits: "Have hair removal in the comfort of your home",
			"selling-product": "Need for a less painful hair removal method",
		},
		{
			"pain-point": "Chapped lips",
			feature: "Made with all-natural ingredients",
			benefits: "Moisturizes",
			"selling-product": "Perfect pout",
		},
	];

	const valueLoader = async () => {
		const user_id = window.localStorage.getItem("user");
		const team_id = window.localStorage.getItem("team");
		const post_data = {
			user_id: user_id,
			team_id: team_id,
		};
		if (!user_id || user_id === "undefined" || user_id === "null") return;

		try {
			const data = await accountDetails(post_data);
			const user_name = data.user_data.user_name;
			setUsername(user_name);
			const names = user_name.split(" ");
			setLastname(names[names.length - 1]);
		} catch (error) {
			return "Error fetching data";
		}
	};

	useEffect(() => {
		(async () => {
			try {
				const data = await valueLoader();
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		})();
	}, []);

	const loginComponent = (
		<Login
			login={userLogin}
			googleLogin={googleLogin}
			setName={setUsername}
			setLastname={setLastname}
		/>
	);

	return (
		<div className={classes["app"]} style={{ display: "flex" }}>
			<BrowserRouter>
				{user && <SideNav logout={userLogout} />}
				<div className={classes["container"]}>
					{user && (
						<Topbar
							lastname={lastname}
							username={username}
							logout={userLogout}
						/>
					)}
					<Routes>
						<Route
							path="/forgot_password"
							element={
								<ForgotPassword
									forgotPassword={forgotPassword}
									verifyOtp={otpVerify}
									passwordReset={passwordReset}
								/>
							}
						/>
						<Route
							path="/ad_analysis"
							element={user ? <AdAnalysis data={dataValues} /> : loginComponent}
						/>
						<Route
							path="/generated_ads"
							element={user ? <GeneratedAds /> : loginComponent}
						/>
						<Route
							path="/ad_edit"
							element={
								user ? (
									<AdEdit comment={userComment} user={user} />
								) : (
									loginComponent
								)
							}
						/>
						<Route
							path="/ad_review"
							element={user ? <AdReview /> : loginComponent}
						/>
						<Route
							path="/publish"
							element={user ? <Publish /> : loginComponent}
						/>
						<Route
							path="/ad_structure"
							element={user ? <AdStructure /> : loginComponent}
						/>
						<Route
							path="/integrations"
							element={user ? <Integrations /> : loginComponent}
						/>
						<Route
							path="/settings"
							element={
								user ? (
									<Settings
										update={userUpdate}
										imageUpdate={imageUpdate}
										getDetails={accountDetails}
									/>
								) : (
									loginComponent
								)
							}
						/>
						<Route path="/" element={user ? <Dashboard /> : loginComponent} />
						<Route path="/privacy" element={<Privacy />} />
						<Route path="/terms" element={<Terms />} />
						<Route
							path="/register"
							element={
								<Register
									register={userRegister}
									googleLogin={googleLogin}
									setName={setUsername}
									setLastname={setLastname}
								/>
							}
						/>
						<Route path="/form" element={<TypeForm questions={questions} />} />
						<Route
							path="/profile"
							element={user ? <Profile /> : loginComponent}
						/>
						<Route
							path="/support"
							element={user ? <Support /> : loginComponent}
						/>
						<Route
							path="/billing"
							element={user ? <Billing user={user} /> : loginComponent}
						/>
						<Route
							path="/success"
							element={user ? <Success /> : loginComponent}
						/>
						<Route path="/verify" element={<Verify />} />
					</Routes>
				</div>
			</BrowserRouter>
		</div>
	);
}

export default App;

{
	/* <script>
  window.fbAsyncInit = function() {
    FB.init({
      appId      : '{your-app-id}',
      cookie     : true,
      xfbml      : true,
      version    : '{api-version}'
    });
      
    FB.AppEvents.logPageView();   
      
  };

  (function(d, s, id){
     var js, fjs = d.getElementsByTagName(s)[0];
     if (d.getElementById(id)) {return;}
     js = d.createElement(s); js.id = id;
     js.src = "https://connect.facebook.net/en_US/sdk.js";
     fjs.parentNode.insertBefore(js, fjs);
   }(document, 'script', 'facebook-jssdk'));
</script> */
}
