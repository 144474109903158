import React from "react";
import classes from "../common_css/Terms.module.css";

const Privacy = (props) => {
	return (
		<div className={classes["terms-container"]}>
			<p className={classes["page-heading"]}>Privacy Policy</p>
			<p className={classes["section-text"]}>
				Volkano-AI is a subsidiary of GradCommerce AI Services Pvt. Ltd.
			</p>
			<div className={classes["section-container"]}>
				<p className={classes["section-heading"]}>Consent</p>
				<p className={classes["section-text"]}>
					By using our website, you hereby consent to our Privacy Policy and
					agree to its terms.
				</p>
				<p className={classes["section-text"]}>Information we collect</p>
				<p className={classes["section-text"]}>
					The personal information that you are asked to provide, and the
					reasons why you are asked to provide it, will be made clear to you at
					the point we ask you to provide your personal information.
				</p>
				<p className={classes["section-text"]}>
					If you contact us directly, we may receive additional information
					about you such as your name, email address, phone number, the contents
					of the message and/or attachments you may send us, and any other
					information you may choose to provide.
				</p>
				<p className={classes["section-text"]}>
					When you register for an Account, we may ask for your contact
					information, including items such as name, company name, address,
					email address, and telephone number.
				</p>
			</div>
			<div className={classes["section-container"]}>
				<p className={classes["section-heading"]}>
					How we use your information
				</p>
				<p className={classes["section-text"]}>
					We use the information we collect in various ways, including to:
				</p>
				<ul className={classes["section-list"]}>
					<li
						className={
							classes["section-list-item"] + " " + classes["section-text"]
						}
					>
						Provide, operate, and maintain our website.
					</li>
					<li
						className={
							classes["section-list-item"] + " " + classes["section-text"]
						}
					>
						Improve, personalize, and expand our website.
					</li>
					<li
						className={
							classes["section-list-item"] + " " + classes["section-text"]
						}
					>
						Understand and analyze how you use our website
					</li>
					<li
						className={
							classes["section-list-item"] + " " + classes["section-text"]
						}
					>
						Develop new products, services, features, and functionality.
					</li>
					<li
						className={
							classes["section-list-item"] + " " + classes["section-text"]
						}
					>
						Communicate with you, either directly or through one of our
						partners, including for customer service, to provide you with
						updates and other information relating to the website, and for
						marketing and promotional purposes.
					</li>
					<li
						className={
							classes["section-list-item"] + " " + classes["section-text"]
						}
					>
						Send you emails.
					</li>
					<li
						className={
							classes["section-list-item"] + " " + classes["section-text"]
						}
					>
						Find and prevent fraud.
					</li>
				</ul>
			</div>
			<div className={classes["section-container"]}>
				<p className={classes["section-heading"]}>Log Files</p>
				<p className={classes["section-text"]}>
					Grad Commerce AI Services follows a standard procedure of using log
					files. These files log visitors when they visit websites. All hosting
					companies do this and a part of hosting services' analytics. The
					information collected by log files include internet protocol (IP)
					addresses, browser type, Internet Service Provider (ISP), date and
					time stamp, referring/exit pages, and possibly the number of clicks.
					These are not linked to any information that is personally
					identifiable. The purpose of the information is for analyzing trends,
					administering the site, tracking users' movement on the website, and
					gathering demographic information.
				</p>
			</div>
			<div className={classes["section-container"]}>
				<p className={classes["section-heading"]}>Cookies and Web Beacons</p>
				<p className={classes["section-text"]}>
					Like any other website, Grad Commerce AI Services uses 'cookies'.
					These cookies are used to store information including visitors'
					preferences, and the pages on the website that the visitor accessed or
					visited. The information is used to optimize the users' experience by
					customizing our web page content based on visitors' browser type
					and/or other information.
				</p>
			</div>
			<div className={classes["section-container"]}>
				<p className={classes["section-heading"]}>
					Advertising Partners Privacy Policies
				</p>
				<p className={classes["section-text"]}>
					You may consult this list to find the Privacy Policy for each of the
					advertising partners of Grad Commerce AI Services.
				</p>
				<p className={classes["section-text"]}>
					Third-party ad servers or ad networks uses technologies like cookies,
					JavaScript, or Web Beacons that are used in their respective
					advertisements and links that appear on Grad Commerce AI Services,
					which are sent directly to users' browser. They automatically receive
					your IP address when this occurs. These technologies are used to
					measure the effectiveness of their advertising campaigns and/or to
					personalize the advertising content that you see on websites that you
					visit.
				</p>
				<p className={classes["section-text"]}>
					Note that Grad Commerce AI Services has no access to or control over
					these cookies that are used by third-party advertisers.
				</p>
			</div>
			<div className={classes["section-container"]}>
				<p className={classes["section-heading"]}>
					Third Party Privacy Policies
				</p>
				<p className={classes["section-text"]}>
					Grad Commerce AI Services's Privacy Policy does not apply to other
					advertisers or websites. Thus, we are advising you to consult the
					respective Privacy Policies of these third-party ad servers for more
					detailed information. It may include their practices and instructions
					about how to opt-out of certain options.
				</p>
				<p className={classes["section-text"]}>
					You can choose to disable cookies through your individual browser
					options. To know more detailed information about cookie management
					with specific web browsers, it can be found at the browsers'
					respective websites.
				</p>
			</div>
			<div className={classes["section-container"]}>
				<p className={classes["section-heading"]}>
					CCPA Privacy Rights (Do Not Sell My Personal Information)
				</p>
				<p className={classes["section-text"]}>
					Under the CCPA, among other rights, California consumers have the
					right to:
				</p>
				<p className={classes["section-text"]}>
					Request that a business that collects a consumer's personal data
					disclose the categories and specific pieces of personal data that a
					business has collected about consumers.
				</p>
				<p className={classes["section-text"]}>
					Request that a business delete any personal data about the consumer
					that a business has collected.
				</p>
				<p className={classes["section-text"]}>
					Request that a business that sells a consumer's personal data, not
					sell the consumer's personal data.
				</p>
				<p className={classes["section-text"]}>
					If you make a request, we have one month to respond to you. If you
					would like to exercise any of these rights, please contact us.
				</p>
			</div>
			<div className={classes["section-container"]}>
				<p className={classes["section-heading"]}>
					GDPR Data Protection Rights
				</p>
				<p className={classes["section-text"]}>
					We would like to make sure you are fully aware of all of your data
					protection rights. Every user is entitled to the following:
				</p>
				<ul className={classes["section-list"]}>
					<li
						className={
							classes["section-list-item"] + " " + classes["section-text"]
						}
					>
						The right to access – You have the right to request copies of your
						personal data. We may charge you a small fee for this service.
					</li>
					<li
						className={
							classes["section-list-item"] + " " + classes["section-text"]
						}
					>
						The right to rectification – You have the right to request that we
						correct any information you believe is inaccurate. You also have the
						right to request that we complete the information you believe is
						incomplete.
					</li>
					<li
						className={
							classes["section-list-item"] + " " + classes["section-text"]
						}
					>
						The right to erasure – You have the right to request that we erase
						your personal data, under certain conditions.
					</li>
					<li
						className={
							classes["section-list-item"] + " " + classes["section-text"]
						}
					>
						The right to restrict processing – You have the right to request
						that we restrict the processing of your personal data, under certain
						conditions.
					</li>
					<li
						className={
							classes["section-list-item"] + " " + classes["section-text"]
						}
					>
						The right to object to processing – You have the right to object to
						our processing of your personal data, under certain conditions.
					</li>
					<li
						className={
							classes["section-list-item"] + " " + classes["section-text"]
						}
					>
						The right to data portability – You have the right to request that
						we transfer the data that we have collected to another organization,
						or directly to you, under certain conditions.
					</li>
					<li
						className={
							classes["section-list-item"] + " " + classes["section-text"]
						}
					>
						If you make a request, we have one month to respond to you. If you
						would like to exercise any of these rights, please contact us.
					</li>
				</ul>
			</div>
			<div className={classes["section-container"]}>
				<p className={classes["section-heading"]}>Children's Information</p>
				<p className={classes["section-text"]}>
					Another part of our priority is adding protection for children while
					using the internet. We encourage parents and guardians to observe,
					participate in, and/or monitor and guide their online activity.
				</p>
				<p className={classes["section-text"]}>
					Grad Commerce AI Services does not knowingly collect any Personal
					Identifiable Information from children under the age of 13. If you
					think that your child provided this kind of information on our
					website, we strongly encourage you to contact us immediately and we
					will do our best efforts to promptly remove such information from our
					records.
				</p>
			</div>
			<div></div>
			<div className={classes["section-container"]}></div>
		</div>
	);
};

export default Privacy;
