import config from "../config";

const baseUrl = config.BASE_URL_PROD;
// const baseUrl = config.BASE_URL_DEV;
const jwtToken =
	localStorage.getItem("token") !== "undefined"
		? "Bearer " + JSON.parse(localStorage.getItem("token"))
		: "";
export const generateRandomString = (length) => {
	const alphanumericChars =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let result = "";
	for (let i = 0; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * alphanumericChars.length);
		result += alphanumericChars.charAt(randomIndex);
	}
	return result;
};

export const loginService = async (data) => {
	let password = data.user_password;
	let email = data.user_email;
	password =
		password.slice(0, 4) +
		generateRandomString(8) +
		password.slice(4, password.length);

	const user_data = {
		user_email: email,
		user_password: password,
	};

	const apiUrl = baseUrl + config.LIS_URL;
	try {
		const response = await fetch(apiUrl, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(user_data),
		});

		const data = await response.json();
		return { data: data, status: response.status };
	} catch (error) {
		return error.toString();
	}
};

export const googleLoginService = async (data) => {
	const apiUrl = baseUrl + config.VTS_URL;
	try {
		const response = await fetch(apiUrl, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		});
		if (response.status === 404) return "Error! Server not reachable";
		const value = await response.json();
		return value;
	} catch (error) {
		return error.toString();
	}
};

export const registerService = async (data) => {
	const email = data.email;
	const full_name = data.name;
	let password = data.password;

	password =
		password.slice(0, 4) +
		generateRandomString(8) +
		password.slice(4, password.length);

	const user_data = {
		user_name: full_name,
		user_email: email,
		user_password: password,
	};

	const apiUrl = baseUrl + config.RES_URL;
	try {
		const response = await fetch(apiUrl, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(user_data),
		});
		const data = await response.json();
		console.log(data);
		if (data.message.includes("Error") || data.message.includes("error"))
			return "Error";
		else return data;
	} catch (error) {
		return error.toString();
	}
};

export const logoutService = async (data) => {
	const apiUrl = baseUrl + config.LOS_URL;
	try {
		const response = await fetch(apiUrl, {
			method: "GET",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
		});
		const data = await response.json();
		window.localStorage.removeItem("user");
		window.localStorage.removeItem("token");
		window.localStorage.removeItem("team");
		return data;
	} catch (error) {
		return error.toString();
	}
};

export const updateService = async (data) => {
	const user_data = {
		address: data.address,
		city: data.city,
		country: data.country,
		pincode: data.pincode,
		state: data.state,
		team_id: data.team_id,
		user_name: data.first_name + " " + data.last_name,
		user_id: data.user_id,
	};
	const apiUrl = baseUrl + config.UAS_URL;
	try {
		const response = await fetch(apiUrl, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: jwtToken,
			},
			body: JSON.stringify(user_data),
		});
		const data = await response.json();
		return data;
	} catch (error) {
		return error.toString();
	}
};

export const commentService = async (data) => {
	const postId = data.postId;

	const post_data = {
		comment_content: data.comment,
		user_id: data.user_id,
		post_id: postId,
	};

	try {
		const response = await fetch(
			`https://flask-hello-world-woad-seven.vercel.app/api/v1/post/${postId}/comment`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: jwtToken,
				},
				body: JSON.stringify(post_data),
			}
		);

		const data = await response.json();
		return data;
	} catch (error) {
		return "Error fetching data";
	}
};

export const passwordResetService = async (data) => {
	const apiUrl = baseUrl + config.FPS_URL;
	try {
		const response = await fetch(apiUrl, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		});

		const value = await response.json();
		window.sessionStorage.setItem("user_id", value.user_id);
		window.sessionStorage.setItem("team", value.team);
		return value;
	} catch (error) {
		return error.toString();
	}
};

export const otpVerifyService = async (data) => {
	const apiUrl = baseUrl + config.VPS_URL;
	try {
		const response = await fetch(apiUrl, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		});

		const value = await response.json();
		return value;
	} catch (error) {
		return error.toString();
	}
};

export const passwordUpdateService = async (data) => {
	const apiUrl = baseUrl + config.UPS_URL;
	try {
		const response = await fetch(apiUrl, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		});

		const value = await response.json();
		return value;
	} catch (error) {
		return error.toString();
	}
};

export const imageUpdateService = async (data) => {
	const apiUrl = baseUrl + config.UIS_URL;
	try {
		const response = await fetch(apiUrl, {
			method: "POST",
			headers: {
				Authorization: jwtToken,
			},
			body: data,
		});

		const value = await response.json();
		return value;
	} catch (error) {
		return error.toString();
	}
};

export const accountDetailsService = async (data) => {
	const apiUrl = baseUrl + config.ADS_URL;
	// console.log(jwtToken);
	try {
		const response = await fetch(apiUrl, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: jwtToken,
			},
			body: JSON.stringify(data),
		});
		const number = await response.json();
		return number;
	} catch (error) {
		return error.toString();
	}
};

export const valueLoaderService = async () => {
	const apiUrl = baseUrl + config.FAS_URL;
	try {
		const response = await fetch(apiUrl, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: jwtToken,
			},
		});
		const value = await response.json();
		return value.facebookAppId;
	} catch (error) {
		return error.toString();
	}
};

export const verifyEmailService = async (data) => {
	const apiUrl = `${baseUrl}${config.VES_URL}`;
	// console.log(apiUrl);
	try {
		const response = await fetch(apiUrl, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		});
		const value = await response.json();
		return value;
	} catch (error) {
		console.log("Error");
		return error.toString();
	}
};
