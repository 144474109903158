import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ad_analysis from "../../../assets/images/ad_analysis.png";
import integration from "../../../assets/images/integration.png";
import ads_setup from "../../../assets/images/ads_setup.png";
import display from "../../../assets/images/display.png";
import publish from "../../../assets/images/publish.png";
import styles from "../../common_css/CommonClasses.module.css";
import {
	Sidebar,
	Menu,
	MenuItem,
	SubMenu,
	SidebarHeader,
	SidebarFooter,
	SidebarContent,
} from "react-pro-sidebar";
import { tokens } from "../../../theme";
import { IconContext } from "react-icons";
import { FaBeer } from "react-icons/fa";
import { RiPencilLine } from "react-icons/ri";
import { BiCog, BiLogOut } from "react-icons/bi";
import { TbLogout } from "react-icons/tb";
import { RxDashboard } from "react-icons/rx";
import { useTheme, Box, Typography, IconButton } from "@mui/material";
// Import sidebar css from react-pro-sidebar module and our custom css.
// The way styles are imported below is throwing an error
// import "react-pro-sidebar/dist/css/styles.css";
// DC2430
// 7B4397
import classes from "./Sidebar.module.css";
// Create a sidebar component for a sidebar at the left of the page. This component will be used in the App.js file.

const Item = ({ title, to, icon, selected, setSelected, navigate }) => {
	const theme = useTheme();
	const colors = tokens(theme.palette);
	const clickHandler = (e, setSelected) => {
		setSelected(e.target.innerText);
	};
	return (
		<MenuItem
			active={selected === title}
			onClick={() => {
				setSelected(title);
				navigate(to);
			}}
			icon={icon}
			components={<Link to={to} />}
		>
			{title}
		</MenuItem>
	);
};
const SideNav = (props) => {
	const theme = useTheme();
	const colors = tokens(theme.palette);
	const [selected, setSelected] = useState("Dashboard");
	const navigate = useNavigate();
	const logoutHandler = () => {
		props.logout();
	};
	const updateActiveNavItem = () => {
		const currentUrl = window.location.pathname.split("/")[1];
		const activeItem = currentUrl.charAt(0).toUpperCase() + currentUrl.slice(1);
		// console.log(activeItem);
		// console.log(currentUrl);
		setSelected(activeItem);
	};

	useEffect(() => {
		updateActiveNavItem();
	}, []);
	return (
		<IconContext.Provider
			value={{
				color: "#fff",
				height: "20px !important",
				width: "20px !important",
			}}
		>
			<Box
				sx={{
					backgroundColor: `${colors.black[500]}`,
					fontFamily: `"Poppins", sans-serif`,
					fontSize: "16px",
					width: "237px",
					// height: "100vh",
					"& .ps-sidebar-root": {
						border: "none!important",
						width: "237px",
						minWidth: "237px",
					},
					"& .ps-sidebar-container": {
						backgroundColor: `${colors.black[500]}`,
						// height: "100vh",
					},
					"& .ps-menu-button": {
						// padding: "5px 35px 5px 20px !important",
						color: `${colors.primary[500]}!important`,
						textAlign: "left",
						borderRadius: "4px",
						// width: "90%",
						margin: " 0 14px 0 22px",
						padding: "10px!important",
					},
					"& .menu-anchor": {
						color: "inherit !important",
						backgroundColor: "transparent !important",
					},
					"& .ps-menu-button:hover": {
						background: `linear-gradient(to right, ${colors.blueAccent[500]}, ${colors.redAccent[500]})!important`,
					},
					"& .ps-menu-button.ps-active": {
						background: `linear-gradient(to right, ${colors.blueAccent[500]}, ${colors.redAccent[500]})!important`,
						transition: "background 3s ease-in-out",
					},
					"& .ps-menu-root": {
						margin: "30px 0 0 0",
					},
					"& .ps-menu-root>ul": {
						display: "flex",
						flexDirection: "column",
						gap: "0.5rem!important",
					},
					"& .ps-menu-icon": {
						marginRight: "0",
						height: "24px",
						width: "24px",
						minWidth: "24px",
					},
					"& .ps-menu-label": {
						fontWeight: "300",
						marginLeft: "8px",
						width: "fit-content",
						fontSize: "16px",
					},
				}}
			>
				<Sidebar sx={{ width: "100%" }}>
					<p className={classes["sidebar-heading"]}>Volkano AI</p>
					<Menu sx={{ gap: "10px" }}>
						<Item
							title="Dashboard"
							to="/"
							icon={<RxDashboard className={classes["sidebar-icon"]} />}
							selected={selected}
							setSelected={setSelected}
							navigate={navigate}
						/>
						<Item
							title="Ad Analysis"
							to="/ad_analysis"
							icon={
								<img
									src={ad_analysis}
									alt="ad-analysis"
									className={styles["sidebar-images"]}
								/>
							}
							selected={selected}
							setSelected={setSelected}
							navigate={navigate}
						/>
						<Item
							title="Generated Ads"
							to="/generated_ads"
							icon={
								<img
									src={display}
									alt="generated ads"
									className={styles["sidebar-images"]}
								/>
							}
							selected={selected}
							setSelected={setSelected}
							navigate={navigate}
						/>
						<Item
							title="Ad Edit"
							to="/ad_edit"
							icon={<RiPencilLine className={classes["sidebar-icon"]} />}
							selected={selected}
							setSelected={setSelected}
							navigate={navigate}
						/>
						<Item
							title="Ready To Publish"
							to="/publish"
							icon={
								<img
									src={publish}
									alt="publish ads"
									className={styles["sidebar-images"]}
								/>
							}
							selected={selected}
							setSelected={setSelected}
							navigate={navigate}
						/>
						<Item
							title="Ad Structure Setup"
							to="/ad_structure"
							icon={
								<img
									src={ads_setup}
									alt="ad structure setup"
									className={styles["sidebar-images"]}
								/>
							}
							selected={selected}
							setSelected={setSelected}
							navigate={navigate}
						/>
						<Item
							title="Integrations"
							to="/integrations"
							icon={
								<img
									src={integration}
									alt="integrations"
									className={styles["sidebar-images"]}
								/>
							}
							selected={selected}
							setSelected={setSelected}
							navigate={navigate}
						/>
						<Item
							title="Settings"
							to="/settings"
							icon={<BiCog className={classes["sidebar-icon"]} />}
							selected={selected}
							setSelected={setSelected}
							navigate={navigate}
						/>
					</Menu>
					<div className={classes["logout-button-section"]}>
						<button
							className={classes["logout-button"]}
							onClick={logoutHandler}
						>
							<TbLogout className={classes["logout-icon"]} /> Logout
						</button>
					</div>
				</Sidebar>
			</Box>
		</IconContext.Provider>
	);
};
export default SideNav;
