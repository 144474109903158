import * as yup from "yup";
import { Formik } from "formik";
import { useFormik } from "formik";
import classes from "./Login.module.css";
import styles from "../common_css/CommonClasses.module.css";
import { GoogleLogin } from "@react-oauth/google";
import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import ErrorToast from "../../components/ErrorToast";
import { ToastContainer, toast } from "react-toastify";
import useMediaQuery from "@mui/material/useMediaQuery";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { FcGoogle } from "react-icons/fc";
import { useGoogleOneTapLogin } from "react-google-one-tap-login";
import axios from "axios";
import LoadingButton from "../../components/LoadingButton/LoadingButton";
import { loginSchema } from "../../schemas";

const Login = (props) => {
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const isNonMobile = useMediaQuery("(min-width:600px)");
	const navigate = useNavigate();
	// const test = useAuth();
	// console.log("login : ", test);
	const handleError = (errorData) => {
		// toast("An error has occurred! Please try again after some time!");
		toast(errorData);
		// setTimeout(() => {
		// 	setError(null);
		// }, 5000);
	};
	const onSubmit = async (values) => {
		setLoading(true);
		console.log(values);
		const email = values.email;
		const password = values.password;
		const data = {
			user_email: email,
			user_password: password,
		};
		const value = await props.login(data);
		if (value?.data?.message.includes("Error")) {
			handleError(value.data.message);
			return;
		}
		if (!value?.message.includes("successful")) handleError(value.message);
		setLoading(false);
	};

	const passwordToggleHandler = (e) => {
		const password = document.querySelector("#password");
		if (e.target.className.includes("fa-eye-slash")) {
			e.target.className = e.target.className.replace("fa-eye-slash", "fa-eye");
			password.setAttribute("type", "text");
		} else {
			e.target.className = e.target.className.replace("fa-eye", "fa-eye-slash");
			password.setAttribute("type", "password");
		}
	};
	const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
		useFormik({
			initialValues: {
				email: "",
				password: "",
			},
			validationSchema: loginSchema,
			onSubmit,
		});
	const googleSuccess = async (res) => {
		const userInfo = await axios
			.get("https://www.googleapis.com/oauth2/v3/userinfo", {
				headers: { Authorization: `Bearer ${res.access_token}` },
			})
			.then((res) => {
				// console.log(res);
				// console.log(res.data);
				return res.data;
			});
		// console.log("user : ", userInfo);

		if (userInfo) {
			const data = await props.googleLogin(userInfo);
			// console.log(data.user_name);
			props.setName(data.user_name);
			const names = data.user_name.split(" ");
			props.setLastname(names[names.length - 1]);
		}
	};
	const login = useGoogleLogin({
		onSuccess: googleSuccess,
		onError: googleSuccess,
		clientId:
			"45272784584-8cm2n74qhvoo3mjh4s8d40tm3377c9sq.apps.googleusercontent.com",
	});

	return (
		<div className={classes["container"]}>
			<div className="login-heading-section ">
				<p className={classes["project-heading"]}>Volkano AI</p>
			</div>
			<div className={classes["login-main-section"]}>
				<div className={classes["login-heading-section"]}>
					<p className={classes["login-heading"]}>Login to Continue</p>
					<p className={classes["login-subheading"]}>
						Login with google or enter your details
					</p>
				</div>
				<form
					onSubmit={handleSubmit}
					className={classes["login-form-section"] + " " + classes["d-flex"]}
				>
					<div className={classes["form-sub-section"]}>
						<label className={classes["inputLabel"]} htmlFor="email">
							Email address
						</label>
						<input
							id="email"
							className={
								classes["input-container"] +
								" " +
								(errors.email && touched.email
									? styles["input-error"]
									: classes["email-input"])
							}
							type="email"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.email}
							placeholder="Enter email"
						/>
						{errors.email && touched.email && (
							<p className={styles["error-text"]}>{errors.email}</p>
						)}
						<label className={classes["inputLabel"]} htmlFor="password">
							Password
						</label>
						<div
							className={
								classes["input-container"] +
								" " +
								(errors.password && touched.password
									? styles["input-error"]
									: "")
							}
							style={{ display: "flex" }}
						>
							<input
								className={classes["input"]}
								id="password"
								type="password"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.password}
								placeholder="********"
							/>
							<i
								className={"far fa-eye-slash " + styles["password-toggle"]}
								id="togglePassword"
								onClick={passwordToggleHandler}
							></i>
						</div>
						{errors.password && touched.password && (
							<p className={styles["error-text"]}>{errors.password}</p>
						)}
						<p
							className={classes["forgot-password-text"]}
							onClick={() => navigate("/forgot_password")}
						>
							Forgot Password?
						</p>
					</div>
					<div className={classes["form-sub-section"]} style={{ gap: "15px" }}>
						<LoadingButton
							text="Login"
							loading={loading}
							disabled={loading}
							type="submit"
						/>
					</div>
					{/* <div className={classes["form-sub-section"]} style={{ gap: "15px" }}>
						<button className={classes["login-button"]} type="submit">
							Login
						</button>
					</div> */}
				</form>
				<button
					onClick={() => login()}
					className={classes["google-login-button"]}
					id="google-btn"
				>
					<FcGoogle className={classes["google-icon"]} />
					Continue with Google
				</button>
				<div className={classes["login-consent-section"]}>
					<p className={classes["login-consent-text"]}>
						By confirming your email, you agree to our{" "}
						<Link to="/terms" className={classes["login-consent-links"]}>
							Terms of Service
						</Link>{" "}
						and that you have read and understood our{" "}
						<Link to="/privacy" className={classes["login-consent-links"]}>
							Privacy Policy
						</Link>
						.
					</p>
					<p className={classes["register-text"]}>
						Don’t have an account.{" "}
						<Link to="/register" className={classes["register-link"]}>
							Sign Up
						</Link>
					</p>
				</div>
			</div>
			<ToastContainer position="bottom-center" autoClose={5000} />
			{/* {error && <ErrorToast message={error} />} */}
		</div>
	);
};

export default Login;
