import { useDroppable } from "@dnd-kit/core";
import { FaMinus } from "react-icons/fa";
import adstyles from "../../pages/adAnalysis/AdAnalysis.module.css";
import classes from "./Droppable.module.css";
import styles from "../../pages/common_css/CommonClasses.module.css";

const Droppable = (props) => {
	// const values = props.items.map((item, idx) => item);
	const { setNodeRef } = useDroppable({
		id: "cart-droppable",
	});

	const clickHandler = (e) => {
		console.log(e);
	};
	return (
		<div className={classes["properties-container"]}>
			<div className={classes["properties-card"]}>
				<p className={adstyles["properties-heading"]}>Pain Points</p>
				<ul className={classes["properties-list"]} ref={setNodeRef}>
					{props.items["pain-point"].map((item, idx) => (
						<li
							id={item}
							key={item}
							className={classes["property-item"] + " " + classes[item]}
							onClick={clickHandler}
						>
							<span>{item}</span>
							<FaMinus className={styles["icon-plain"]} />
						</li>
					))}
				</ul>
			</div>
			<div className={classes["properties-card"]}>
				<p className={adstyles["properties-heading"]}>Features</p>
				<ul className={classes["properties-list"]} ref={setNodeRef}>
					{props.items["feature"].map((item, idx) => (
						<li
							key={item}
							className={classes["property-item"] + " " + classes[item]}
						>
							<span>{item}</span>
							<FaMinus className={styles["icon-plain"]} />
						</li>
					))}
				</ul>
			</div>
			<div className={classes["properties-card"]}>
				<p className={adstyles["properties-heading"]}>Selling Products</p>
				<ul className={classes["properties-list"]} ref={setNodeRef}>
					{props.items["selling-product"].map((item, idx) => (
						<li
							key={item}
							className={classes["property-item"] + " " + classes[item]}
						>
							<span>{item}</span>
							<FaMinus className={styles["icon-plain"]} />
						</li>
					))}
				</ul>
			</div>
			<div className={classes["properties-card"]}>
				<p className={adstyles["properties-heading"]}>Benefits</p>
				<ul className={classes["properties-list"]} ref={setNodeRef}>
					{props.items["benefits"].map((item, idx) => (
						<li
							key={item}
							className={classes["property-item"] + " " + classes[item]}
						>
							<span>{item}</span>
							<FaMinus className={styles["icon-plain"]} />
						</li>
					))}
				</ul>
			</div>

			<button className={classes["generate-idea-button"]}>
				Generated Idea
			</button>
		</div>
	);
};

export default Droppable;
