import { useDraggable } from "@dnd-kit/core";
import { FC } from "react";
import styles from "./Draggable.module.css";
import { CSS } from "@dnd-kit/utilities";
import { FaCircleMinus } from "react-icons/fa6";

const Draggable = (props) => {
	const { attributes, listeners, setNodeRef, transform } = useDraggable({
		id: props.children,
		data: { title: props.children, classes: props },
	});
	return (
		<span
			ref={setNodeRef}
			style={{ transform: CSS.Transform.toString(transform) }}
			{...attributes}
			{...listeners}
		>
			{props.children}
		</span>
	);
};

export default Draggable;
