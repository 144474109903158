import React, { useState } from "react";
import classes from "./TypeForm.module.css";
const TypeForm = ({ questions }) => {
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const [answers, setAnswers] = useState(Array(questions.length).fill(""));
	const currentQuestion = questions[currentQuestionIndex];
	const [selectedOption, setSelectedOption] = useState(null);
	const isLastQuestion = currentQuestionIndex === questions.length;

	const handleInputChange = (event) => {
		const { value } = event.target;
		const updatedAnswers = [...answers];
		updatedAnswers[currentQuestionIndex] = value;
		setAnswers(updatedAnswers);
		setSelectedOption(value);
	};

	const handleOptionChange = (event) => {
		const { value } = event.target;
		const updatedAnswers = [...answers];
		updatedAnswers[currentQuestionIndex] = value;
		setAnswers(updatedAnswers);
		setSelectedOption(value);
	};

	const handleNext = () => {
		// Store the selected answer and move to the next question
		const updatedAnswers = [...answers];
		updatedAnswers[currentQuestionIndex] = selectedOption || "skipped";
		setAnswers(updatedAnswers);
		setCurrentQuestionIndex(currentQuestionIndex + 1);
		setSelectedOption(null);
	};

	const handleSkip = () => {
		// Store the current answer as "skipped" and move to the next question
		const updatedAnswers = [...answers];
		updatedAnswers[currentQuestionIndex] = "skipped";
		setAnswers(updatedAnswers);

		if (currentQuestionIndex < questions.length - 1) {
			setCurrentQuestionIndex(currentQuestionIndex + 1);
			setSelectedOption(null); // Reset the selected option when moving to the next question
		}
	};
	return (
		<div className={classes["container"]}>
			<div className={classes["top-section"]}>
				<p className={classes["page-heading"]}>Volkano AI</p>
			</div>
			<div className={classes["form-main-section"]}>
				{isLastQuestion ? (
					<p className={classes["thank-you"]}>
						Thank you for answering all the questions!
					</p>
				) : (
					<>
						<p className={classes["form-section-text"]}>
							{currentQuestion.text}
						</p>
						{currentQuestion.options ? (
							<div className={classes["options-container"]}>
								{currentQuestion.options.map((option, index) => (
									<div className={classes["option-section"]}>
										<input
											type="radio"
											value={option}
											checked={answers[currentQuestionIndex] === option}
											onChange={handleOptionChange}
											className={classes["input-button"]}
										/>
										<label
											className={
												classes["options-label"] +
												" " +
												(option == selectedOption
													? classes["selected-label"]
													: "")
											}
											key={index}
										>
											{option}
										</label>
									</div>
								))}
							</div>
						) : (
							<input
								className={classes["input-field"]}
								type="text"
								value={answers[currentQuestionIndex]}
								onChange={handleInputChange}
							/>
						)}
						<div className={classes["buttons-section"]}>
							<button className={classes["next-button"]} onClick={handleNext}>
								Next
							</button>
							<button className={classes["skip-button"]} onClick={handleSkip}>
								Skip
							</button>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default TypeForm;
