import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AuthProvider } from "./store/AuthContext";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<GoogleOAuthProvider clientId="45272784584-8cm2n74qhvoo3mjh4s8d40tm3377c9sq.apps.googleusercontent.com">
			<AuthProvider>
				<App />
			</AuthProvider>
		</GoogleOAuthProvider>
	</React.StrictMode>
);
