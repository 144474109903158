const BASE_URL_PROD = "https://flask-hello-world-woad-seven.vercel.app";
const BASE_URL_DEV = "http://127.0.0.1:5000";
const ADS_URL = "/api/v1/account";
const UIS_URL = "/api/v1/updateImage";
const VPS_URL = "/api/v1/verifyOtp";
const FPS_URL = "/api/v1/forgotPassword";
const UAS_URL = "/api/v1/updateAccount";
const LOS_URL = "/api/v1/logout";
const RES_URL = "/api/v1/register";
const UPS_URL = "/api/v1/updatePassword";
const VTS_URL = "/api/v1/verifyGoogleToken";
const LIS_URL = "/api/v1/login";
const FAS_URL = "/api/v1/getFacebookAppId";
const VES_URL = "/api/v1/verifyEmail";

export default {
	BASE_URL_PROD,
	BASE_URL_DEV,
	ADS_URL,
	UIS_URL,
	VPS_URL,
	FPS_URL,
	UAS_URL,
	LOS_URL,
	RES_URL,
	UPS_URL,
	VTS_URL,
	LIS_URL,
	FAS_URL,
	VES_URL,
};
