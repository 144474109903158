import React, { useEffect, useState } from "react";
import StripeForm from "./StripeForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import classes from "./Billing.module.css";

const Billing = (props) => {
	const [stripeDetails, setStripeDetails] = useState(null);
	const valueLoader = async () => {
		if (props.user === "undefined") return;
		const jwtToken =
			"Bearer " + JSON.parse(window.localStorage.getItem("token"));
		try {
			const response = await fetch(
				`https://flask-hello-world-woad-seven.vercel.app/api/v1/getStripeDetails`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: jwtToken,
					},
				}
			);
			const data = await response.json();
			return data;
		} catch (error) {
			return "Error fetching data";
		}
	};

	useEffect(() => {
		(async () => {
			try {
				const data = await valueLoader();
				// console.log(data);
				setStripeDetails(data);
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		})();
	}, []);
	return stripeDetails ? (
		<stripe-pricing-table
			pricing-table-id={
				stripeDetails?.pricingTableId
					? stripeDetails.pricingTableId
					: "price_1JQ4ZtGZqX6ZQZ1Z0QZ0QZ1Z"
			}
			publishable-key={
				stripeDetails?.publishableKey ? stripeDetails.publishableKey : ""
			}
			client-reference-id={props.user}
		></stripe-pricing-table>
	) : (
		<div>Loading...</div>
	);
};

export default Billing;
