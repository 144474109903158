import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../store/AuthContext";
import styles from "../common_css/CommonClasses.module.css";
const Verify = () => {
	const navigate = useNavigate();
	const { verifyEmail } = useAuth();
	useEffect(() => {
		(async () => {
			try {
				const urlParams = new URLSearchParams(window.location.search);
				const token = urlParams.get("token");
				const user_id = urlParams.get("user_id");

				if (!token) {
					navigate("/register");
				}
				const verification = await verifyEmail({
					token: token,
					user_id: user_id,
				});
				// console.log(verification);
				navigate("/");
			} catch (error) {
				navigate("/register");
			}
		})();
	}, []);
	return (
		<div className={styles["d-flex" + " "]}>
			<p className={styles["page-heading-text"]}>Volkano AI</p>
			<p
				className={styles["text"] + " " + styles["regular-text"] + " "}
				style={{ marginTop: "3rem" }}
			>
				Please wait while we verfify your email.
			</p>
		</div>
	);
};

export default Verify;
