import React, { useState } from "react";
import classes from "./ForgotPassword.module.css";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

const ForgotPassword = (props) => {
	const [keyRequested, setKeyRequested] = useState(null);
	const [passwordResetKey, setPasswordResetKey] = useState(false);
	const navigate = useNavigate();

	const resendHandler = async (values) => {
		const data = await props.forgotPassword(values);
	};

	const submitHandler = async (values) => {
		window.sessionStorage.setItem("email", values.email);
		const data = await props.forgotPassword(values);
		setKeyRequested(data.email);
	};

	const otpSubmitHandler = async (values) => {
		const otp = `${values.one}${values.two}${values.three}${values.four}${values.five}`;
		const data_values = {
			otp: otp,
			email: keyRequested,
		};
		const data = await props.verifyOtp(data_values);
		if (data.message.includes("OTP verified successfully")) {
			setPasswordResetKey(true);
		} else {
			alert("Invalid OTP");
		}
	};

	const passwordSubmitHandler = async (values) => {
		const password = values.password;
		const confirm_password = values.confirm_password;
		if (password != confirm_password) {
			alert("Passwords do not match");
			return;
		}
		const data_values = {
			password: password,
			email: keyRequested,
			team: window.sessionStorage.getItem("team"),
			user_id: window.sessionStorage.getItem("user_id"),
		};
		props.passwordReset(data_values);
		navigate("/");
	};

	const formik = useFormik({
		initialValues: {
			email: "",
		},
		onSubmit: submitHandler,
	});

	const otpFormik = useFormik({
		initialValues: {
			one: "",
			two: "",
			three: "",
			four: "",
			five: "",
		},
		onSubmit: otpSubmitHandler,
	});

	const passwordFormik = useFormik({
		initialValues: {
			password: "",
			confirm_password: "",
		},
		onSubmit: passwordSubmitHandler,
	});

	return (
		<div className={classes["container"]}>
			<div className={classes["heading-section"]}>
				<p className={classes["heading"] + " " + classes["page-heading"]}>
					Volkano AI
				</p>
			</div>
			{passwordResetKey ? (
				<div className={classes["main-section"]}>
					<div
						className={
							classes["heading-section"] + " " + classes["otp-heading-section"]
						}
					>
						<p className={classes["heading"]}>Set New Password?</p>
						<p
							className={
								classes["section-text"] + " " + classes["fp-section-text"]
							}
						>
							Must be atleast 8 characters.
						</p>
					</div>
					<div className={classes["form"] + " " + classes["password-form"]}>
						<form
							className={
								classes["form-section"] + " " + classes["password-form-section"]
							}
							onSubmit={passwordFormik.handleSubmit}
						>
							<label
								className={
									classes["section-text"] + " " + classes["inputLabel"]
								}
								htmlFor="email"
							>
								Type password
							</label>
							<input
								className={classes["input"]}
								id="password"
								name="password"
								type="password"
								onChange={passwordFormik.handleChange}
								value={passwordFormik.values.password}
								placeholder="********"
							/>
							<label
								className={
									classes["section-text"] + " " + classes["inputLabel"]
								}
								htmlFor="email"
							>
								Confirm password
							</label>
							<input
								className={classes["input"]}
								id="confirm_password"
								name="confirm_password"
								type="password"
								onChange={passwordFormik.handleChange}
								value={passwordFormik.values.confirm_password}
								placeholder="********"
							/>
							<button
								className={classes["submit-button"] + " " + classes["button"]}
								type="submit"
							>
								Reset Password
							</button>
							<button
								className={classes["back-button"] + " " + classes["button"]}
								onClick={() => navigate("/")}
							>
								Back to Login
							</button>
						</form>
					</div>
				</div>
			) : keyRequested ? (
				<div className={classes["main-section"]}>
					<div
						className={
							classes["heading-section"] + " " + classes["otp-heading-section"]
						}
					>
						<p className={classes["heading"]}>Password Recovery</p>
						<p
							className={
								classes["section-text"] + " " + classes["fp-section-text"]
							}
						>
							Type the recovery code send to your email- {keyRequested}
						</p>
					</div>
					<div className={classes["form"]}>
						<p
							className={
								classes["form-heading"] + " " + classes["section-text"]
							}
						>
							Type OTP Code
						</p>
						<form
							className={classes["otp-section"]}
							onSubmit={otpFormik.handleSubmit}
						>
							<div className={classes["otp-input-section"]}>
								<input
									className={classes["input"] + " " + classes["otp-input"]}
									id="one"
									name="one"
									type="text"
									inputMode="numeric"
									maxLength={1}
									onChange={otpFormik.handleChange}
									value={otpFormik.values.one}
									placeholder="-"
								/>
								<input
									className={classes["input"] + " " + classes["otp-input"]}
									id="two"
									name="two"
									type="text"
									inputMode="numeric"
									maxLength={1}
									onChange={otpFormik.handleChange}
									value={otpFormik.values.two}
									placeholder="-"
								/>
								<input
									className={classes["input"] + " " + classes["otp-input"]}
									id="three"
									name="three"
									type="text"
									inputMode="numeric"
									maxLength={1}
									onChange={otpFormik.handleChange}
									value={otpFormik.values.three}
									placeholder="-"
								/>
								<input
									className={classes["input"] + " " + classes["otp-input"]}
									id="four"
									name="four"
									type="text"
									inputMode="numeric"
									maxLength={1}
									onChange={otpFormik.handleChange}
									value={otpFormik.values.four}
									placeholder="-"
								/>
								<input
									className={classes["input"] + " " + classes["otp-input"]}
									id="five"
									name="five"
									type="text"
									inputMode="numeric"
									maxLength={1}
									onChange={otpFormik.handleChange}
									value={otpFormik.values.five}
									placeholder="-"
								/>
							</div>
							<button
								className={
									classes["submit-button"] +
									" " +
									classes["button"] +
									" " +
									classes["otp-verify-button"]
								}
								type="submit"
							>
								Continue
							</button>
							<p
								className={
									classes["section-text"] + " " + classes["resend-mail-text"]
								}
							>
								Didn't receive an email?
							</p>
							<button
								className={
									classes["back-button"] +
									" " +
									classes["resend-button"] +
									" " +
									classes["button"]
								}
								onClick={resendHandler}
							>
								Resend
							</button>
							<button
								className={classes["back-button"] + " " + classes["button"]}
								onClick={() => navigate("/")}
							>
								Back to Login
							</button>
						</form>
					</div>
				</div>
			) : (
				<div className={classes["main-section"]}>
					<div className={classes["heading-section"]}>
						<p className={classes["heading"]}>Forgot Your Password?</p>
						<p
							className={
								classes["section-text"] + " " + classes["fp-section-text"]
							}
						>
							No worries, we will sent you reset instructions.
						</p>
					</div>
					<div className={classes["form"]}>
						<form
							className={classes["form-section"]}
							onSubmit={formik.handleSubmit}
						>
							<label
								className={
									classes["section-text"] + " " + classes["inputLabel"]
								}
								htmlFor="email"
							>
								Email address
							</label>
							<input
								className={classes["input"]}
								id="email"
								name="email"
								type="email"
								onChange={formik.handleChange}
								value={formik.values.name}
								placeholder="Enter email"
							/>
							<button
								className={classes["submit-button"] + " " + classes["button"]}
								type="submit"
							>
								Reset Password
							</button>
							<button
								className={classes["back-button"] + " " + classes["button"]}
								onClick={() => navigate("/")}
							>
								Back to Login
							</button>
						</form>
					</div>
				</div>
			)}
		</div>
	);
};

export default ForgotPassword;
