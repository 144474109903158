import * as yup from "yup";

const passswordRules =
	/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
const passwordMessage =
	"Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character";

export const loginSchema = yup.object().shape({
	email: yup.string().email("Please enter a valid email").required("Required"),
	password: yup
		.string()
		.min(8)
		.matches(
			passswordRules,
			"Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
		)
		.required("Required"),
});
export const registerSchema = yup.object().shape({
	name: yup.string(),
	email: yup.string().email("Please enter a valid email").required("Required"),
	password: yup
		.string()
		.min(8)
		.matches(
			passswordRules,
			"Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
		)
		.required("Required"),
	checkbox: yup.boolean(),
});
