import React, { useState, useEffect } from "react";
import Topbar from "../../components/Topbar/Topbar";
import classes from "./AdEdit.module.css";
import profile from "../../assets/images/profile.jpg";
import { useFormik } from "formik";

const AdEdit = (props) => {
	const [comments, setComments] = useState([]);
	// const submitHandler = async (values) => {}
	const submitHandler = async (values) => {
		console.log("here");
		console.log();
		console.log(props);
		const currentUrl = window.location.search;
		const urlParams = new URLSearchParams(currentUrl);
		const postId = urlParams.get("post");
		const data = {
			comment: values.comment,
			user_id: props.user.user_id,
			postId: postId,
		};
		const value = await props.comment(data);
		formik.setValues({
			comment: "",
		});
		// if (value.includes("Error")) return;
		setComments([...comments, values.comment]);
	};
	const formik = useFormik({
		initialValues: {
			comment: "",
		},
		onSubmit: submitHandler,
	});
	return (
		<div className={classes["container"]}>
			<div className={classes["main-section"]}>
				<div className={classes["ad-section"]}>
					<div className={classes["ad-info-section"]}>
						<p className={classes["ad-info-heading"]}>Ads Edit</p>
					</div>
					<div className={classes["ad-comment-section"]}>
						<div className={classes["new-comment-section"]}>
							<div className={classes["user-info-section"]}>
								<img
									className={classes["profile-image"]}
									src={profile}
									alt="profile-picture"
								></img>
								<p className={classes["user-profile-text"]}>
									{props.user.user_name ? props.user.user_name : "User"}
								</p>
							</div>
							<form
								className={classes["new-comment-input-section"]}
								onSubmit={formik.handleSubmit}
							>
								<input
									id="comment"
									name="comment"
									type="text"
									className={classes["new-comment-input"]}
									placeholder="Write a comment"
									onChange={formik.handleChange}
									value={formik.values.comment}
								/>
								<button
									className={classes["comment-post-button"]}
									type="submit"
								>
									Post
								</button>
							</form>
						</div>
						<div className={classes["comments-display-section"]}>
							<p className={classes["comments-heading"]}>
								<span className={classes["comments-heading-large"]}>
									Reviews
								</span>
								<span className={classes["view-all-text"]}>View All</span>
							</p>
							<div className={classes["comments-section"]}>
								{comments.length > 0 ? (
									comments.map((comment, idx) => (
										<div className={classes["comment-item"]}>
											{/* top profile bar to be added  */}
											<p className={classes["comment-text"]}>{comment}</p>
										</div>
									))
								) : (
									<></>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className={classes["ad-edit-____-section"]}></div>
			</div>
		</div>
	);
};

export default AdEdit;
