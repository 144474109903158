import { useState, useEffect } from "react";
import { BsFacebook } from "react-icons/bs";
import { FacebookProvider, LoginButton } from "react-facebook";
import styles from "../../pages/common_css/CommonClasses.module.css";
import classes from "../../pages/global/settings/Settings.module.css";

export default function ReactFacebookLogin() {
	const [facebookAppId, setFacebookAppId] = useState(null);
	const handleSuccess = (data) => {
		console.log(data);
	};
	const responseFacebook = (response) => {
		console.log(response);
	};
	const valueLoader = async () => {
		// console.log("here");
		const jwtToken = "Bearer " + JSON.parse(localStorage.getItem("token"));
		// console.log(jwtToken);
		try {
			// const response = await fetch(
			// 	`https://flask-hello-world-woad-seven.vercel.app/api/v1/getFacebookAppId`,
			const response = await fetch(
				`https://flask-hello-world-woad-seven.vercel.app/api/v1/getFacebookAppId`,
				// const response = await fetch(
				// 	`http://127.0.0.1:5000/api/v1/get-facebook-app-id`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: jwtToken,
					},
				}
			);
			const value = await response.json();
			console.log("Here ", value);
			setFacebookAppId(value.facebookAppId);
			return value.facebookAppId;
		} catch (error) {
			console.log("Error fetching data:", error);
		}
	};

	useEffect(() => {
		(async () => {
			try {
				const data = await valueLoader();
				// console.log(data);
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		})();
	}, []);

	return facebookAppId ? (
		<FacebookProvider appId={facebookAppId}>
			<LoginButton
				onError={handleSuccess}
				onSuccess={handleSuccess}
				className={styles["button"] + " " + classes["facebook-button"]}
			>
				Log In With <BsFacebook className={styles["icon"]} />
			</LoginButton>
		</FacebookProvider>
	) : (
		<button
			className={
				styles["button"] +
				" " +
				classes["facebook-button"] +
				" " +
				styles["disabled"]
			}
			disabled={true}
		>
			Log In With <BsFacebook className={styles["icon"]} />
		</button>
	);
}
