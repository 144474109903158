import React, { createContext, useState, useContext, useEffect } from "react";
import {
	loginService,
	logoutService,
	registerService,
	updateService,
	commentService,
	passwordResetService,
	otpVerifyService,
	passwordUpdateService,
	imageUpdateService,
	accountDetailsService,
	googleLoginService,
	verifyEmailService,
} from "../services/authServices";

export const AuthContext = createContext({
	currentUser: null,
	team: null,
	username: "User",
	userComment: () => {},
	login: () => {},
	logout: () => {},
	register: () => {},
	update: () => {},
	googleLogin: () => {},
	forgotPassword: () => {},
	otpVerify: () => {},
	passwordReset: () => {},
	accountDetails: async () => {},
	setUsername: () => {},
});

export function useAuth() {
	return useContext(AuthContext);
}

export function AuthProvider({ children }) {
	const [currentUser, setCurrentUser] = useState(null);
	const [team, setTeam] = useState(null);
	const [username, setUsername] = useState("User");

	useEffect(() => {
		const storedUser = localStorage.getItem("user");
		if (storedUser !== "undefined") {
			setCurrentUser(JSON.parse(storedUser));
			setTeam(localStorage.getItem("team"));
		}
	}, []);

	async function googleLogin(data) {
		const user = await googleLoginService(data);
		if (
			typeof user === "string" &&
			(user.includes("Failed to fetch") || user.includes("Error"))
		)
			return { message: "Error! Server not reachable" };
		// console.log(user.user_id);
		// console.log(user.team);
		// console.log(user.token);
		const uid = user["user_id"];
		window.localStorage.setItem("user", JSON.stringify(uid));
		window.localStorage.setItem("token", JSON.stringify(user.token));
		window.localStorage.setItem("team", JSON.stringify(user.team));
		setCurrentUser(user);
		setTeam(user.team);
		return { message: "Login successful", user_name: user["user_name"] };
	}

	async function login(data) {
		const user = await loginService(data);
		if (user.status !== 200) return user;
		if (typeof user === "string" && user.includes("Failed to fetch"))
			return { message: "Error! Server not reachable" };
		const uid = user.data["user_id"];
		window.localStorage.setItem("user", JSON.stringify(uid));
		window.localStorage.setItem("token", JSON.stringify(user.data.token));
		window.localStorage.setItem("team", JSON.stringify(user.data.team));
		setCurrentUser(user.data);
		setTeam(user.data.team);
		return { message: "Login successful" };
	}

	function logout() {
		logoutService();
		setCurrentUser(null);
	}

	async function register(data) {
		const user = await registerService(data);
		console.log("user", user);
		if (
			typeof user === "string" &&
			(user.includes("SyntaxError") || user.includes("TypeError"))
		)
			return "Error";
		if (user === "error") return "Error";
		return user;
	}

	async function update(data) {
		const value = await updateService(data);
		return value;
	}

	async function comment(data) {
		const value = await commentService(data);
		// console.log(value);
		return value;
	}

	async function forgotPassword(data) {
		const value = await passwordResetService(data);
		return value;
	}

	async function otpVerify(data) {
		const value = await otpVerifyService(data);
		return value;
	}

	async function passwordReset(data) {
		const value = await passwordUpdateService(data);
		return value;
	}
	async function imageUpdate(data) {
		const value = await imageUpdateService(data);
		return value;
	}

	async function accountDetails(data) {
		const value = await accountDetailsService(data);
		return value;
	}
	async function verifyEmail(data) {
		const value = await verifyEmailService(data);
		return value;
	}
	const value = {
		team: team,
		user: currentUser,
		userLogin: login,
		userLogout: logout,
		userRegister: register,
		userUpdate: update,
		userComment: comment,
		forgotPassword: forgotPassword,
		otpVerify: otpVerify,
		passwordReset: passwordReset,
		imageUpdate: imageUpdate,
		accountDetails: accountDetails,
		googleLogin: googleLogin,
		username: username,
		setUsername: setUsername,
		verifyEmail: verifyEmail,
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
