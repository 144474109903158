import React, { useRef, useEffect, useState } from "react";
import { useFormik } from "formik";
import classes from "./Settings.module.css";
import styles from "../../common_css/CommonClasses.module.css";
import profile from "../../../assets/images/profile.jpg";
import { set } from "date-fns";
import { MdOutlineContentCopy } from "react-icons/md";
import { BsFacebook } from "react-icons/bs";
import ReactFacebookLogin from "../../../components/FacebookLogin/FacebookLogin";

const Settings = (props) => {
	const user_id = window.localStorage.getItem("user");
	const team_id = window.localStorage.getItem("team");

	const [profileData, setProfileData] = useState(null);

	const copyHandler = () => {
		navigator.clipboard.writeText(
			document.querySelector("#affiliate-text").textContent
		);
	};
	const submitHandler = async (values) => {
		values["user_id"] = user_id;
		values["team_id"] = team_id;
		const data = await props.update(values);
	};
	const formik = useFormik({
		initialValues: {
			first_name: "",
			last_name: "",
			address: "",
			country: "",
			state: "",
			city: "",
			pincode: "",
		},
		onSubmit: submitHandler,
	});
	const cancelDataHandler = () => {
		formik.setValues({
			first_name: "",
			last_name: "",
			email: "",
			address: "",
			country: "",
			state: "",
			city: "",
			pincode: "",
			checkbox: false,
		});
	};

	useEffect(() => {
		const fetchData = async () => {
			const first_name = document.getElementById("first_name");
			const last_name = document.getElementById("last_name");
			const address = document.getElementById("address");
			const country = document.getElementById("country");
			const state = document.getElementById("state");
			const city = document.getElementById("city");
			const pincode = document.getElementById("pincode");

			// console.log(first_name);
			// console.log(last_name);
			// console.log(address);
			// console.log(country);
			// console.log(state);
			// console.log(city);
			// console.log(pincode);

			const values = {
				user_id: user_id,
				team_id: team_id,
			};

			const data = await props.getDetails(values);

			const last_name_value = data.user_data?.user_name
				? data.user_data?.user_name.split(" ").slice(-1)[0]
				: "";

			formik.setValues({
				first_name: data.user_data?.user_name
					? data.user_data?.user_name.split(" ").slice(0, -1).join(" ")
					: "",
				last_name: last_name_value ? last_name_value : "",
				email: "",
				address: data?.user_data?.address ? data?.user_data?.address : "",
				country: data?.user_data?.country ? data?.user_data?.country : "",
				state: data?.user_data?.state ? data?.user_data?.state : "",
				city: data?.user_data?.city ? data?.user_data?.city : "",
				pincode: data?.user_data?.pincode ? data?.user_data?.pincode : "",
				checkbox: false,
			});
			setProfileData(data);
		};
		fetchData();
	}, []);

	const imageInputRef = useRef(null);
	const handleFileChange = (event) => {
		const file = event.target.files[0];

		if (file) {
			const image = document.getElementById("user-image-settings");
			const brand_name = document.getElementById("company-url").textContent;
			const data = new FormData();
			const reader = new FileReader();

			reader.onload = (e) => {
				image.src = e.target.result;

				data.append("image_data", file);
				data.append("user_id", window.localStorage.getItem("user"));
				data.append("brand_name", brand_name);

				props.imageUpdate(data);
			};

			reader.readAsDataURL(file);
		}
	};
	const imageUpdateHandler = () => {
		imageInputRef.current.click();
		// props.update();
	};

	const makePayment = async () => {};
	return (
		<div className={classes["main-container"]}>
			<div className={classes["settings-container"]}>
				<p className={classes["section-heading"]}>Settings</p>
				<div className={classes["settings-main-section"]}>
					<form
						className={classes["personal-account-info"]}
						onSubmit={formik.handleSubmit}
					>
						<div className={classes["personal-info-section"]}>
							<p className={classes["personal-info-heading-text"]}>Account</p>
							<p className={classes["personal-info-section-text"]}>
								This information will be displayed publicly so be careful what
								you share.
							</p>
							<div className={classes["personal-info-input-section"]}>
								<label
									className={
										classes["inputLabel"] + " " + classes["regular-text"]
									}
									htmlFor="first_name"
								>
									First Name
								</label>
								<input
									className={classes["input"] + " " + classes["regular-text"]}
									id="first_name"
									name="first_name"
									type="text"
									onChange={formik.handleChange}
									value={formik.values.first_name}
									placeholder="Enter first name"
								/>

								<label
									className={
										classes["inputLabel"] +
										" " +
										classes["inputMargin"] +
										" " +
										classes["regular-text"]
									}
									htmlFor="last_name"
								>
									Last Name
								</label>
								<input
									className={classes["input"] + " " + classes["regular-text"]}
									id="last_name"
									name="last_name"
									type="text"
									onChange={formik.handleChange}
									value={formik.values.last_name}
									placeholder="Enter last name"
								/>
								<p
									className={
										classes["inputLabel"] +
										" " +
										classes["inputMargin"] +
										" " +
										classes["regular-text"]
									}
								>
									Affiliate Link
								</p>
								<p
									id="affiliate-text"
									className={
										classes["input"] +
										" " +
										classes["affiliate-text"] +
										" " +
										classes["regular-text"]
									}
								>
									<span>https://vlkn.ai?ref_code='xzx23h'</span>
									<MdOutlineContentCopy
										className={classes["copy-button"]}
										onClick={copyHandler}
									/>
								</p>
							</div>
						</div>
						<div className={classes["personal-info-section"]}>
							<p className={classes["personal-info-heading-text"]}>
								Company Information
							</p>
							<p className={classes["personal-info-section-text"]}>
								This information will be displayed publicly so be careful what
								you share.
							</p>
							<div className={classes["personal-info-input-section"]}>
								<label
									className={
										classes["inputLabel"] + " " + classes["regular-text"]
									}
									htmlFor="first_name"
								>
									Company URL
								</label>
								<p
									className={
										classes["input"] +
										" " +
										classes["company-url"] +
										" " +
										classes["regular-text"]
									}
									id="company-url"
								>
									deyga.in
								</p>

								<label
									className={
										classes["inputLabel"] +
										" " +
										classes["inputMargin"] +
										" " +
										classes["regular-text"]
									}
									htmlFor="address"
								>
									Address
								</label>
								<input
									className={classes["input"] + " " + classes["regular-text"]}
									id="address"
									name="address"
									type="text"
									onChange={formik.handleChange}
									value={formik.values.address}
									placeholder="Enter Address"
								/>
								<label
									className={
										classes["inputLabel"] +
										" " +
										classes["inputMargin"] +
										" " +
										classes["regular-text"]
									}
									htmlFor="country"
								>
									Country
								</label>
								<input
									className={classes["input"] + " " + classes["regular-text"]}
									id="country"
									name="country"
									type="text"
									onChange={formik.handleChange}
									value={formik.values.country}
									placeholder="Country"
								/>
								<label
									className={
										classes["inputLabel"] +
										" " +
										classes["inputMargin"] +
										" " +
										classes["regular-text"]
									}
									htmlFor="state"
								>
									State
								</label>
								<input
									className={classes["input"] + " " + classes["regular-text"]}
									id="state"
									name="state"
									type="text"
									onChange={formik.handleChange}
									value={formik.values.state}
									placeholder="State"
								/>
								<label
									className={
										classes["inputLabel"] +
										" " +
										classes["inputMargin"] +
										" " +
										classes["regular-text"]
									}
									htmlFor="city"
								>
									City
								</label>
								<input
									className={classes["input"] + " " + classes["regular-text"]}
									id="city"
									name="city"
									type="text"
									onChange={formik.handleChange}
									value={formik.values.city}
									placeholder="City"
								/>
								<label
									className={
										classes["inputLabel"] +
										" " +
										classes["inputMargin"] +
										" " +
										classes["regular-text"]
									}
									htmlFor="pincode"
								>
									Pincode
								</label>
								<input
									className={classes["input"] + " " + classes["regular-text"]}
									id="pincode"
									name="pincode"
									type="text"
									onChange={formik.handleChange}
									value={formik.values.pincode}
									placeholder="Pincode"
								/>
							</div>
							<div className={classes["forms-button-section"]}>
								<button
									className={classes["image-remove-button"]}
									onClick={cancelDataHandler}
								>
									Cancel
								</button>
								<button className={classes["image-add-button"]} type="submit">
									Save
								</button>
							</div>
						</div>
					</form>
					<div className={classes["company-info-display-section"]}>
						<div className={classes["owner-info-section"]}>
							<div className={classes["owner-info-heading-section"]}>
								<p className={classes["owner-info-heading-text"]}>Profile</p>
								<p className={classes["owner-info-section-text"]}>
									This information will be displayed publicly so be careful what
									you share.
								</p>
							</div>
							<div className={classes["owner-info-heading-section"]}>
								<img
									className={classes["owner-image"]}
									src={
										""
										// "https://flask-hello-world-woad-seven.vercel.app" +
										// profileData?.image_url +
										// "?profile=" +
										// user_id
									}
									alt="profile"
									id="user-image-settings"
								/>
								<input
									type="file"
									accept="image/*"
									onChange={handleFileChange}
									style={{ display: "none" }}
									id="fileInput"
									ref={imageInputRef}
								/>
								<button
									className={classes["image-remove-button"]}
									onClick={imageUpdateHandler}
								>
									Change
								</button>
								<button className={classes["image-add-button"]}>Remove</button>
							</div>
						</div>
						<div
							className={classes["company-description"]}
							// onSubmit={formik.handleSubmit}
						>
							<p
								className={
									classes["company-description-heading"] +
									" " +
									classes["regular-text"]
								}
							>
								Description
							</p>
							<p
								className={
									classes["input"] +
									" " +
									classes["regular-text"] +
									" " +
									classes["description-text"]
								}
							>
								Lorem ipsum dolor sit amet consectetur. Pulvinar viverra posuere
								vitae neque congue at volutpat sit. Imperdiet hendrerit sit
								fermentum a amet. Phasellus aliquet condimentum nec non
								scelerisque nec risus.
							</p>
						</div>
						{/* <div style={{ display: "flex" }}>
							<p className={classes["regular-text"]}>Stripe Test</p>
							<button
								className={classes["stripe-button"]}
								onClick={makePayment}
							>
								Checkout
							</button>
						</div> */}
						<div
							className={
								styles["d-flex"] + " " + classes["facebook-link-section"]
							}
						>
							<p className={styles["regular-text"]}>
								Connect your Facebook Ads Account
							</p>
							<ReactFacebookLogin />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Settings;
