import React, { useState } from "react";
import { FaRegBell, FaChevronDown } from "react-icons/fa6";
import { BsChevronDown } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import classes from "./Topbar.module.css";
import profile from "../../assets/images/profile.jpg";
const Topbar = (props) => {
	const navigate = useNavigate();
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const dropdown = [
		props.username,
		"Profile",
		"Settings",
		"Billing",
		"Support",
		"Logout",
	];
	const navigationSelectHandler = (e) => {
		const target = e.target.textContent;
		const target_link = target.charAt(0).toLowerCase() + target.slice(1);
		if (target_link !== "logout") navigate("/" + target_link);
		else props.logout();
		setIsDropdownOpen(false);
	};
	const dropdownHandler = () => {
		setIsDropdownOpen((prevState) => !prevState);
	};
	return (
		<div className={classes["topbar-container"]}>
			<div className={classes["welcome-text-section"]}>
				<p className={classes["personal-text"]}>
					Hello, {props.lastname}{" "}
					<span className={classes["welcome-text"]}>Welcome back!</span>
				</p>
			</div>
			<div className={classes["personal-info-section"]}>
				<FaRegBell className={classes["bell-icon"]} />
				<div
					className={classes["info-display-section"]}
					onClick={dropdownHandler}
				>
					<img className={classes["profile-picture"]} src={profile}></img>
					<p className={classes["profile-name-text"]}>{props.username}</p>
					<FaChevronDown className={classes["chevron-down-icon"]} />
				</div>
				{isDropdownOpen ? (
					<ul className={classes["dropdown-section"]}>
						{dropdown.map((product, index) => (
							<li
								className={
									classes["navigation-item"] +
									" " +
									(product == props.username
										? classes["navigation-item-active"]
										: "")
								}
								key={index + 1}
								onClick={navigationSelectHandler}
							>
								{product}
							</li>
						))}
					</ul>
				) : (
					<></>
				)}
			</div>
		</div>
	);
};

export default Topbar;
